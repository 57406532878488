import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import DonateButton from "components/DonateButton";
import { Markdown } from "components/Markdown";

import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import img1 from "src/images/community/serving1.jpg";
import img2 from "src/images/community/serving2.jpg";

export default function Page() {
  return (
    <BlogLayout slug="serving-more-people">
      <Paragraph component="div">
        <Markdown>
          {`
At EOCP we build personalized, trusting, and authentic relationships with people experiencing homelessness, and walk alongside them as they grow into a life of self-reliance. Through strengthening key partnerships, we have boosted our team's capacity to **connect more people with the critical support services they need** so they can care for themselves and their future.

So far this year **over a dozen individuals have transitioned from our Crossroads shelter into their new homes** across Alameda County.

  `}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Local Housing Options Keep People in their Community
        <Markdown>{`More affordable permanent housing units in Oakland, Berkeley, and Fremont have opened up in the recent past, supporting EOCP's team efforts to swiftly find suitable placements. We are primarily seeing **success in finding local housing placements for seniors aged 62+**,  with the growing availability of buildings for seniors in Berkeley. This is key as seniors are the fastest growing unhoused population in California. From 2017 to 2021, California's overall older adult population grew by 7%, but [the number of people 55 and over who sought homelessness services increased 84%](https://www.bcsh.ca.gov/calich/hdis.html) --- more than any other age group --- according to the state's Homeless Data Integration System (KQED, 2023).  Many are experiencing homelessness for the first time after age 50.`}</Markdown>
      </Blockquote>

      <Paragraph component="div">
        <Box
          component="img"
          src={img1}
          sx={{ width: "40%", float: "left" }}
          mr={3}
          mb={3}
        />
        <Markdown>
          {`
"When clients arrive at EOCP through the Coordinated Entry process and complete an initial assessment, they specify which region of Alameda County they would like to live in," shared Myeeka Calhoun, Case Management Services Manager at EOCP's Crossroads shelter. "Since many of our clients already have family connections, go to the doctor, or send their kids to schools in Oakland, it is ideal for them to stay here. Attending monthly regional cross-agency meetings helps our team stay tuned in to new buildings opening up in the area, as well as other emerging resources in the community that can support the process of moving out of homelessness."
  `}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Strengthening the Collaborative Rhythm
        <Markdown>{`Through trusted community building relationships, we are **strengthening the collaborative rhythm between EOCP and other Alameda County partner organizations**.  Together we are discovering crucial opportunities for our shelter residents. By working with [Homeless Action Center](https://homelessactioncenter.org/), we are able to better advocate for clients' access to legal benefits programs. For individuals living with HIV/AIDS or who are transitioning into medical respite, we have been able to directly work with [Life Long Medical Care](https://lifelongmedical.org/locations/east-oakland-health-center/) and other health clinics to quickly connect them  to the care they need. We have been able to grow our capacity from serving 25 immunocompromised clients at a time, to 60+ individuals in distanced, safe, hotel-based shelter. `}</Markdown>
      </Blockquote>

      <Paragraph component="div">
        <Box
          component="img"
          src={img2}
          sx={{ width: "40%", float: "right" }}
          ml={3}
          mb={3}
        />
        <Markdown>
          {`

  By **increasing critical communication pathways with key partners**, EOCP has activated our network across the county to mutually share resources, availability of shelter beds, and information about opportunities for creative ways to service  more individuals and families so they can move towards stability, housing, and good health.

  We look forward to your support as we continue the mission of EOCP.

          `}
        </Markdown>
      </Paragraph>
      <DonateButton />
    </BlogLayout>
  );
}
